import React from 'react'
import { graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFilePdf,

} from '@fortawesome/free-solid-svg-icons'
import newWaveTop from '../images/new-wave-top.png';
import newWaveBottom from '../images/new-wave-bottom.png';
import { navigate } from '@reach/router'

class DomesticBurningIndex extends React.Component {


    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const pageTitle = get(this, 'props.data.contentfulAirPollutionFromDomesticBurning.name')
        const rows = get(
            this,
            'props.data.contentfulAirPollutionFromDomesticBurning.rows'
        )

        const buttons = get(
            this,
            'props.data.contentfulAirPollutionFromDomesticBurning.buttons'
        )

        const header = get(this, 'props.data.contentfulAirPollutionFromDomesticBurning.header')
        const headerMobile = get(
            this,
            'props.data.contentfulAirPollutionFromDomesticBurning.headerMobile'
        )

        const metaDescription = get(
            this,
            'props.data.contentfulAirPollutionFromDomesticBurning.metaDescription'
        )
        const metaImageURL = get(
            this,
            'props.data.contentfulAirPollutionFromDomesticBurning.metaImage.file.url'
        )

        const contentClickHandler = (e) => {
            const targetLink = e.target.closest('a');
            if (!targetLink) return;


            if (targetLink.href.includes('#smokearea')) {
                navigate('#smokearea');
                e.preventDefault();

            }
            else if (!targetLink.href.includes('https://cleanairgm.com') && !targetLink.href.includes('http://localhost')) {
                window.open(targetLink.href, '_blank');
                e.preventDefault();
            }

        };

        return (
            <Layout location={this.props.location} hideNewsLetter>
                <div>
                    <Helmet title={pageTitle + ' | ' + siteTitle}>
                        <meta name="description" content={metaDescription} />
                        <meta
                            property="og:url"
                            content={'https://cleanairgm.com/air-pollution-from-domestic-burning'}
                        />
                        <meta property="og:type" content={'website'} />
                        <meta property="og:title" content={pageTitle + ' | ' + siteTitle} />
                        <meta property="og:description" content={metaDescription} />
                        <meta property="og:image" content={metaImageURL} />

                    </Helmet>


                    <div className="page-banner">
                        <Img
                            fluid={header.fluid}
                            className="page-banner__img d-none d-lg-block"
                        />
                        <Img
                            fluid={headerMobile.fluid}
                            className="page-banner__img d-block d-lg-none"
                        />
                        <h1 style={{ maxWidth: '400px' }}>{pageTitle}</h1>
                    </div>


                    <Container className="mb-5 mt-5 text-center text-lg-left">
                        <Row>

                            <Col xs={{ size: 12, order: 0 }} md={{ size: 6, order: 0 }} className="vc mt-4 mt-md-0">
                                <h2 className='h2-potential'>
                                    {rows[0].title}
                                </h2>
                                <div
                                    onClick={contentClickHandler}
                                    dangerouslySetInnerHTML={{
                                        __html: rows[0].text.childMarkdownRemark.html,
                                    }}
                                ></div>
                                <div className='text-center text-lg-left mt-4'>
                                    {rows[0] && rows[0].buttons && rows[0].buttons.map(b => (
                                        <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                    ))}

                                </div>
                            </Col>
                            <Col className="vc" xs={12} md={{ size: 6 }}>
                                {rows[0].image && (
                                    <Img className="br-10" fluid={rows[0].image.fluid} />
                                )}
                            </Col>

                        </Row>
                    </Container>

                    <Container className="mb-5 mt-5 text-center text-lg-left">
                        <Row>

                            <Col xs={{ size: 12, order: 0 }} md={{ size: 5, offset: 1, order: 1 }} className="vc mt-4 mt-md-0">
                                <h2 className='h2-potential'>
                                    {rows[1].title}
                                </h2>
                                <div
                                    onClick={contentClickHandler}

                                    dangerouslySetInnerHTML={{
                                        __html: rows[1].text.childMarkdownRemark.html,
                                    }}
                                ></div>
                                <div className='text-center text-lg-left mt-4'>
                                    {rows[1] && rows[1].buttons && rows[1].buttons.map(b => (
                                        <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                    ))}

                                </div>
                            </Col>
                            <Col className="vc" xs={12} md={{ size: 6 }}>
                                {rows[1].image && (
                                    <Img className="br-10" fluid={rows[1].image.fluid} />
                                )}
                            </Col>

                        </Row>
                    </Container>

                    <div className='smoke-control'>
                        <Container className="mt-5 text-center text-lg-left">
                            <Row>

                                <Col xs={{ size: 12, order: 0 }} md={{ size: 6, offset: 1, order: 1 }} className="mt-4 mt-md-0">
                                    <h2 className='h2-potential'>
                                        {rows[2].title}
                                    </h2>
                                    <div
                                        onClick={contentClickHandler}

                                        dangerouslySetInnerHTML={{
                                            __html: rows[2].text.childMarkdownRemark.html,
                                        }}
                                    ></div>
                                    <div className='text-center text-lg-left mt-4'>
                                        {rows[2] && rows[2].buttons && rows[2].buttons.map(b => (
                                            <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                        ))}

                                    </div>
                                </Col>
                                <Col className="" xs={12} md={{ size: 5 }}>
                                    {rows[2].image && (
                                        <Img className="br-10 w-50 w-md-100 ml-auto mr-auto" fluid={rows[2].image.fluid} />

                                    )}
                                </Col>

                            </Row>
                        </Container>
                    </div>

                    {/* <Container className="mb-5 mt-5 text-center text-lg-left">
                        <Row>

                            <Col xs={{ size: 12, order: 0 }} md={{ size: 6, order: 0 }} className="vc mt-4 mt-md-0">
                                <h2 className='h2-potential'>
                                    {rows[3].title}
                                </h2>
                                <div
                                    onClick={contentClickHandler}

                                    dangerouslySetInnerHTML={{
                                        __html: rows[3].text.childMarkdownRemark.html,
                                    }}
                                ></div>
                                <div className='text-center text-lg-left mt-4'>
                                    {rows[3] && rows[3].buttons && rows[3].buttons.map(b => (
                                        <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                    ))}

                                </div>
                            </Col>
                            <Col className="vc" xs={12} md={{ size: 6 }}>
                                {rows[3].image && (
                                    <Img className="br-10" fluid={rows[3].image.fluid} />
                                )}
                            </Col>

                        </Row>
                    </Container> */}

                    <div id="garden" className="proposed-zone">
                        <Container className=" text-center text-lg-left">
                            <Row>

                                <Col xs={{ size: 12, order: 0 }} md={{ size: 8, order: 0 }} className="vc mt-4 mt-md-0">
                                    <h2 className='h2-potential'>
                                        {rows[4].title}
                                    </h2>
                                    <div
                                        onClick={contentClickHandler}

                                        dangerouslySetInnerHTML={{
                                            __html: rows[4].text.childMarkdownRemark.html,
                                        }}
                                    ></div>
                                    <div className='text-center text-lg-left mt-4'>
                                        {rows[4] && rows[4].buttons && rows[4].buttons.map(b => (
                                            <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                        ))}

                                    </div>
                                </Col>
                                <Col className="vc" xs={12} md={{ size: 3, offset: 1 }}>
                                    {rows[4].image && (
                                        <Img className="br-10 w-25 w-md-100 ml-auto mr-auto" fluid={rows[4].image.fluid} />
                                    )}
                                </Col>

                            </Row>
                        </Container>
                    </div>


                    <Container className="mb-5 mt-5 text-center text-lg-left">
                        <Row>

                            <Col xs={{ size: 12, order: 0 }} md={{ size: 5, order: 1, offset: 1 }} className="vc mt-4 mt-md-0">
                                <h2 className='h2-potential'

                                    dangerouslySetInnerHTML={{
                                        __html: rows[5].title,
                                    }} />

                                <div
                                    onClick={contentClickHandler}

                                    dangerouslySetInnerHTML={{
                                        __html: rows[5].text.childMarkdownRemark.html,
                                    }}
                                ></div>
                                <div className='text-center text-lg-left mt-4'>
                                    {rows[5] && rows[5].buttons && rows[5].buttons.map(b => (
                                        <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                    ))}

                                </div>
                            </Col>
                            <Col className="vc" xs={12} md={{ size: 6 }}>
                                {rows[5].image && (
                                    <Img className="br-10" fluid={rows[5].image.fluid} />
                                )}
                            </Col>

                        </Row>
                    </Container>
                    <div>
                        <img src={newWaveTop} className='w-100 wave-height' />

                        <div className='smoke-buttons mb-0 pb-0'>
                            <Container id="smokearea" className="text-center">
                                <h2 className='pb-4'>Greater Manchester local council smoke and pollution webpages</h2>

                                {buttons && buttons.map(b => (
                                    <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                ))}



                            </Container>

                        </div>
                        <img src={newWaveBottom} className='w-100 wave-height' />

                    </div>

                    <Container className="mb-5 pt-5 text-left">
                        <Row>

                            <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 0 }} className="vc mt-4 mt-md-0">
                                <h2 className='h2-potential text-center'

                                    dangerouslySetInnerHTML={{
                                        __html: rows[6].title,
                                    }} />

                                <div
                                    onClick={contentClickHandler}

                                    dangerouslySetInnerHTML={{
                                        __html: rows[6].text.childMarkdownRemark.html,
                                    }}
                                ></div>
                                <div className='text-lg-left mt-4'>
                                    {rows[6] && rows[6].buttons && rows[6].buttons.map(b => (
                                        <a className={`btn btn-cons btn-cons--${b.colour ? b.colour.replace(' ', '').toLowerCase() : ''}`} href={b.url} target={b.openInNewWindow ? '_blank' : ''}>{b.showPdfIcon ? <FontAwesomeIcon className="mr-2" icon={faFilePdf} /> : null}{b.title}</a>

                                    ))}

                                </div>
                            </Col>
                            <Col className="vc" xs={12} md={{ size: 6 }}>
                                {rows[6].image && (
                                    <Img className="br-10" fluid={rows[6].image.fluid} />
                                )}
                            </Col>

                        </Row>
                    </Container>


                </div>
            </Layout>
        )
    }
}

export default DomesticBurningIndex

export const pageQuery = graphql`
  query DomesticBurningQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulAirPollutionFromDomesticBurning {
      id
      name
      metaDescription
      metaImage {
        file {
          url
        }
      }
      header {
        fluid(maxWidth: 1920, maxHeight: 450, quality: 95) {
          ...GatsbyContentfulFluid
        }
      }
      headerMobile {
        fluid(maxWidth: 1080, maxHeight: 720) {
          ...GatsbyContentfulFluid
        }
      }
      rows {
        title
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 670) {
            ...GatsbyContentfulFluid
          }
        }
        buttons {
          title,
          url,
          openInNewWindow,
          colour,
          showPdfIcon,
        }
      }
      buttons {
        title,
        url,
        openInNewWindow,
        colour,
        showPdfIcon,
      }
    }
  }
`

